import React from 'react';
import ReactMarkdown from 'react-markdown';
import { useParams, useHistory, useLocation } from 'react-router-dom';
import rehypeRaw from 'rehype-raw';
import '../Blog.css';

class Blog extends React.Component {
  state = { content: '# Periler hikayeyi getiriyor...', isPrevDisabled: false, isNextDisabled: false };

  componentDidMount() {
    const { slug } = this.props.params;
    this.loadMarkdown(slug);
    this.checkNavigation(slug);
  }

  componentDidUpdate(prevProps) {
    const { slug } = this.props.params;
    if (slug !== prevProps.params.slug) {
      window.scrollTo(0, 0);
      this.loadMarkdown(slug); // Load markdown for the new slug
      this.checkNavigation(slug); // Check navigation for the new slug
    }
  }

  loadMarkdown = (slug) => {
    import(`../markdown/${slug.replace("0", "")}.md`)
      .then((module) => fetch(module.default))
      .then((res) => res.text())
      .then((text) => {
        let modifiedText = text;

        if (slug.includes("0")) {
          modifiedText = modifiedText
            .replace(/Mecnun/g, 'Mertcan')
            .replace(/Furkan/g, 'Fatih')
            .replace(/Emre/g, 'Efe')
            .replace(/Zeyo/g, 'Zehra')
            .replace(/Senyao/g, 'Seda');
        }

        document.title = modifiedText.split('\n')[0].replace('#', '').trim();
        this.setState({ content: modifiedText });
      })
      .catch((err) => console.error('Markdown yüklenemedi:', err));
  };

  getAdjacentSlug = (slug, step) => {
    const num = parseInt(slug.split('-').pop(), 10) + step;
    const hasLeadingZero = slug.split('-').pop().startsWith('0');
    const newSlug = num > 0 ? slug.replace(/-\d+$/, `-${num}`) : null;

    return hasLeadingZero && num < 10 ? newSlug.replace(/-\d+$/, `-0${num}`) : newSlug;
  };

  checkNavigation = async (slug) => {
    const prevSlug = this.getAdjacentSlug(slug, -1);
    const nextSlug = this.getAdjacentSlug(slug, 1);
    const prevExists = prevSlug ? await this.checkIfPageExists(prevSlug) : false;
    const nextExists = await this.checkIfPageExists(nextSlug);

    this.setState({ isPrevDisabled: !prevExists, isNextDisabled: !nextExists });
  };

  checkIfPageExists = async (slug) => {
    try {
      await import(`../markdown/${slug}.md`);
      return true;
    } catch {
      return false;
    }
  };

  handleNavigation = async (slug) => {
    await this.loadMarkdown(slug); // Load the new content first
    await this.checkNavigation(slug); // Check if prev/next exist after loading new content
    this.props.history.push(`/blog/${slug}`); // Navigate to the new slug
  };

  render() {
    const { slug } = this.props.params;
    const { isPrevDisabled, isNextDisabled } = this.state;

    return (
      <div className="blog-post">
        <div style={this.state.content.length > 50 ? {} : { display: "none" }} className="navigate">
          <button onClick={() => this.handleNavigation(this.getAdjacentSlug(slug, -1))} disabled={isPrevDisabled}>
            Önceki
          </button>

          <button onClick={() => this.handleNavigation(this.getAdjacentSlug(slug, 1))} disabled={isNextDisabled}>
            Sonraki
          </button>
        </div>

        <ReactMarkdown rehypePlugins={[rehypeRaw]}>{this.state.content}</ReactMarkdown>

        <div style={this.state.content.length > 50 ? {} : { display: "none" }} className="navigate">
          <button onClick={() => this.handleNavigation(this.getAdjacentSlug(slug, -1))} disabled={isPrevDisabled}>
            Önceki
          </button>

          <button onClick={() => this.handleNavigation(this.getAdjacentSlug(slug, 1))} disabled={isNextDisabled}>
            Sonraki
          </button>
        </div>
      </div>
    );
  }
}

export default (props) => <Blog {...props} params={useParams()} history={useHistory()} location={useLocation()} />;